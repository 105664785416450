<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('owner.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-row>
      <v-col cols="6">
        {{ $t('owner.uuid') }}
      </v-col>
      <v-col cols="6">
        {{ detail.uuid }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('owner.name') }}
      </v-col>
      <v-col cols="6">
        {{ detail.name }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('owner.phone') }}
      </v-col>
      <v-col cols="6">
        {{ detail.phone_number }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('owner.email') }}
      </v-col>
      <v-col cols="6">
        {{ detail.email }}
      </v-col>
    </v-row>
    <div class="mt-6">
      {{ $t('general.bankInfo') }}
    </div>
    <hr class="my-2" />
    <v-row>
      <v-col cols="6">
        {{ $t('general.bankName') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bank_info ? detail.bank_info.bank_name : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.accountHolder') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bank_info ? detail.bank_info.account_holder : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.accountNumber') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bank_info ? detail.bank_info.account_number : '' }}
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.transaction.basePath,
    }),
  },
  methods: {},
};
</script>
